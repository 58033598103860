<template>
  <div>
    <div class="header-text">
      <h1>{{ $t("legalNotice.title") }}</h1>
    </div>
    <section class="container content">
      <div class="">
        <p>
          {{ $t("legalNotice.description") }}
        </p>
      </div>
      <div class="row my-3">
        <div class="col-md-6 my-3">
          <div
            class="card shadow-sm p-3 contact-card"
            v-html="$t('legalNotice.addressInfo')"
          ></div>
        </div>
        <div class="col-md-6 my-3">
          <div
            class="card shadow-sm p-3 contact-card"
            v-html="$t('legalNotice.businessInfo')"
          ></div>
        </div>
        <div class="col-md-6 my-3">
          <div
            class="card shadow-sm p-3 contact-card"
            v-html="$t('legalNotice.technicalInfo')"
          ></div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </section>
    <section class="container content">
      <h3>{{ $t("legalNotice.securedTransaction") }}</h3>
      <p>
        {{ $t("legalNotice.securedTransactionContent") }}
      </p>
    </section>
    <section class="container content">
      <h3>{{ $t("legalNotice.bestPriceGuaranteed") }}</h3>
      <p>
        {{ $t("legalNotice.bestPriceGuaranteedContent") }}
      </p>
    </section>
    <section class="container content">
      <h3>{{ $t("legalNotice.officialWebiste") }}</h3>
      <p>
        {{ $t("legalNotice.officialWebisteContent") }}
      </p>
    </section>
    <section class="container content">
      <h1>{{ $t("legalNotice.noWithdrawal") }}</h1>
      <p>
        {{ $t("legalNotice.noWithdrawalContent") }}
      </p>
    </section>
    <section class="container content">
      <h3>{{ $t("legalNotice.consumerMediator") }}</h3>
      <p>
        {{ $t("legalNotice.consumerMediatorContent") }}
      </p>
      <div class="row my-3">
        <div class="col-md-6 my-3">
          <div class="card shadow-sm p-3 contact-card">
            <div>{{ $t("legalNotice.tourismAndTravel") }}</div>
            <div
              class="mt-3 text-muted"
              v-html="$t('legalNotice.tourismAndTravelContent')"
            ></div>
          </div>
        </div>
      </div>
    </section>
    <section class="container content">
      <h1>{{ $t("legalNotice.legalInfo") }}</h1>
      <p>
        {{ $t("legalNotice.legalInfoContent") }}
      </p>
      <div class="row my-3">
        <div class="col-md-6 my-3">
          <div
            class="card shadow-sm p-3 contact-card text-muted"
            v-html="$t('legalNotice.legalInfoContentAddress')"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LegalNotice",
  components: {},
};
</script>

<style scoped>
.header-text {
  padding: 30px 0 60px 0;
  color: #fff;
  background: #ff762b;
  color: white;
  text-align: center;
}

.header-text h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 45px;
}

.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content h3 {
  font-size: 28px;
  color: #444444;
}
.content p {
  color: #969696;
  line-height: 35px;
}

.contact-card {
  line-height: 30px;
}
@media only screen and (max-width: 500px) {
  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .content h1 {
    font-size: 28px;
  }

  .content h3 {
    font-size: 25px;
  }

  .header-text h1 {
    font-size: 30px;
  }
}
</style>
